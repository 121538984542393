<template>
  <div>
    <div class="pinspy-load" v-if="loading"><span class="load"></span></div>
    <div class="login-form" :style="{ backgroundImage: `url(${backgroundUrl})` }">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="card px-5">
              <div class="card-header email-header text-center bg-transparent">
                <div class="for-email mt-3">
                  <h1 class="heading-enter-email">Verify Email</h1>
                  <p class="Enter-email">
                    Please enter the verification code sent to your email.
                  </p>
                </div>
              </div>
              <div class="card-body py-4 px-0">
                <Form @submit="onSubmit">
                  <div class="alert alert-danger" v-if="showError">{{ errorMsg }}</div>
                  <div class="alert alert-success" v-if="showSuccess">{{ message }}</div>

                  <!-- Verification Code Boxes -->
                  <div class="form-group d-flex justify-content-between">
                    <input
                      v-for="(digit, index) in verificationCode"
                      :key="index"
                      type="text"
                      maxlength="1"
                      class="form-control code-box shadow-none"
                      v-model="verificationCode[index]"
                      ref="codeBoxes"
                      @keypress="allowOnlyNumbers($event)"
                      @input="onInput($event, index)"
                      @keydown.backspace="onBackspace($event, index)"
                      @paste="onPaste"
                    />
                  </div>

                  <div class="login-btn">
                    <button type="submit" class="btn btn-done">
                      Verify
                      <img :src="require('@/assets/images/arrow-right.svg')" />
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backgroundUrl from "@/assets/images/login-banner.png";
import logoImage from "@/assets/images/logo-1.png";
import axios from 'axios';
import authHeader from '../services/auth-header';
import { Form } from "vee-validate";
import authService from "../services/auth.service";

export default {
  name: "VerifyEmail",
  components: {
    Form,
  },
  data() {
    return {
      backgroundUrl,
      logoImage,
      showError: false,
      showSuccess: false,
      errorMsg: "",
      loading: false,
      message: "",
      email: "",
      verificationCode: ["", "", "", "", "", ""],
    };
  },
   watch: {
      $route(to, from) {
         console.log('Navigated from', from.path, 'to', to.path);
      }
   },
  mounted() {
    this.email = this.$route.query.email || "";
    // Automatically focus the first input box
    this.$nextTick(() => {
      this.$refs.codeBoxes[0].focus();
    });
  },
  methods: {
    allowOnlyNumbers(event) {
      // Allow only numbers (key codes 48-57 for 0-9)
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    onInput(event, index) {
      // Move to the next box on input
      if (event.target.value.length === 1 && index < this.verificationCode.length - 1) {
        this.$refs.codeBoxes[index + 1].focus();
      }
    },
    onBackspace(event, index) {
      // Move to the previous box on backspace
      if (!event.target.value && index > 0) {
        this.$refs.codeBoxes[index - 1].focus();
      }
    },
    onPaste(event) {
      // Get the pasted data
      const pasteData = event.clipboardData.getData("text").trim();

      // Remove non-numeric characters
      const numericData = pasteData.replace(/\D/g, "");

      // Check if the numeric data matches the length of the verification code
      if (numericData.length === this.verificationCode.length) {
        for (let i = 0; i < numericData.length; i++) {
          this.verificationCode[i] = numericData[i];
        }

        // Focus the last input box
        this.$refs.codeBoxes[this.verificationCode.length - 1].focus();
      }

      // Prevent default paste behavior
      event.preventDefault();
    },
    onSubmit() {
      this.loading = true;
      this.showError = false;
      // Check if all verification code fields are filled
      const isCodeComplete = this.verificationCode.every(digit => digit !== "");

      if (!isCodeComplete) {
        this.showError = true;
        this.errorMsg = "Please fill in all the verification code fields.";
        this.loading = false;
        return;
      }


      const code = this.verificationCode.join("");

      this.$store
        .dispatch("auth/verifyEmail", { email: this.email, code: code })
        .then((response) => {
          this.loading = false;
          if (response.status === true) {
            this.showError = false;
            this.showSuccess = true;
            const userData = JSON.parse(localStorage.getItem('userDate'));
            console.log("Here's the userData on verification page---->", userData);
            this.sendToZapier(userData);
            this.message = response.message;
            this.userDetails = response.data;
            const subscription = localStorage.getItem('subscription');
            if (subscription != 1) {
              this.$router.push("/subscription");
              return
            } else {
              this.$router.push("/search-ad");
            }
            this.$router.push("/my-account");
          } else {
            this.showError = true;
            this.errorMsg = response.message;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError = true;
          this.errorMsg = "An unexpected error occurred.";
          console.error(error);
        });
    },
    sendToZapier(userData) {
         const zapierWebhookUrl = process.env.VUE_APP_API_URL+'zapier';
         axios.post(zapierWebhookUrl, userData).then(
            (response) => {
               console.log('Zapier Response:', response);
               if (response.status === 200) {
                  console.log('Zapier Response:------------>', response);
               } else {
                  this.showError = true;
                  this.errorMsg = 'Error sending data to Zapier';
               }
            }
         ).catch(
            (error) => {
               this.showError = true;
               this.errorMsg = 'Error sending data to Zapier';
               console.error('Zapier Error:', error);
            }
         );
      },
  },
};
</script>

<style>
.code-box {
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}
</style>
