<template>
<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W6HDLLR" height="0" width="0" style="display:none;visibility:hidden"></iframe>
   <section class="sticky-header">
      <div class="navigation-wrap bg-light-1 start-header my-account-header">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-3 col-lg-2 align-self-center d-none d-md-block">
                  <div class="site-logo site-top-logo">
                     <div class="logo-icon">
                        <router-link :to="{'name': 'Ad Search'}" style="border:none;"  v-if="loggedIn">
                           <img :src="require('@/assets/images/logo_red.svg')">
                        </router-link>
                        <router-link :to="{'name': 'home'}" style="border:none;" v-else>
                           <img :src="require('@/assets/images/logo_red.svg')"/>
                        </router-link>
                     </div>
                  </div>
               </div>
               <div class=" col-md-9 col-lg-10  align-self-center">
                  <nav class="navbar navbar-expand-md navbar-light">
                     <router-link :to="{'name': 'home'}" style="border:none;" class="d-md-none ">
                        <img :src="require('@/assets/images/logo_red.svg')"/>
                     </router-link>
                     <button class="navbar-toggler bg-transparent border-0 ml-auto theme-navbar-toggle" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                     <span class="navbar-toggler-icon"></span>
                     </button>
                     <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="loggedIn">
                        <ul class="navbar-nav top-menu">
                           <li class="nav-item" v-if="subscription && subscription !== '0'">
                              <router-link class="nav-link nav-link-my-acunt " :to="{'name': 'Ad Search'}">Ad Search</router-link>
                           </li>
                           <li class="nav-item" v-else>
                              <router-link class="nav-link nav-link-my-acunt" :to="{'name': 'subscription'}">Ad Search</router-link>
                           </li>
                           <li class="nav-item">
                              <router-link class="nav-link nav-link-my-acunt " :to="{'name': 'My Account'}">My Account</router-link>
                           </li>
                           <li class="nav-item">
                              <a href="https://pinspy.com/help-center/" class="nav-link nav-link-my-acunt" target="_blank" test="test">Help Center</a>
                              <!-- <router-link class="nav-link nav-link-my-acunt" :to="{ path: 'pinspy.com/help-center/' }" target="_blank" >Help Center</router-link> -->
                           </li>
                           <!-- <li class="nav-item">
                              <router-link class="nav-link nav-link-my-acunt" :to="{'name': 'example'}">Example</router-link>
                           </li> -->
                           <li class="nav-item logout-btn">
                              <a class="nav-link my-account-logout red-button btn-large" @click.prevent="logout()">Log out</a>
                           </li>
                           <li class="nav-item trial-status" v-if="trialActive">
                              <router-link class="nav-link" :to="{'name': 'My Account'}">
                                 <span class="trial-counter">
                                    Trial Active - {{ trialDaysLeft }} Days Left
                                 </span>
                              </router-link>
                           </li>
                        </ul>
                     </div>
                     <div class="collapse navbar-collapse" id="navbarSupportedContent" v-else>
                        <ul class="navbar-nav top-menu">
                           <li class="nav-item logout-btn" v-if="loggedIn">
                              <a class="nav-link my-account-logout red-button btn-large" @click.prevent="logout()">Logout</a>
                           </li>
                           <li class="nav-item  red-link" v-else>
                              <a class="nav-link " @click.prevent="logout()">Login</a>
                           </li>
                           <li class="nav-item logout-btn"  v-if="loggedIn">
                              <router-link class="nav-link mx-0 ml-3 button-white btn-large theme-btn red-outline-btn" :to="{'name': 'subscription'}">Get started</router-link>
                           </li>
                           <li class="nav-item logout-btn" v-else>
                              <router-link class="nav-link mx-0 ml-3 my-account-logout red-button btn-large" :to="{'name': 'register'}">Get started</router-link>
                           </li>
                        </ul>
                     </div>
                  </nav>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>
<script>
   export default {
     data() {
      return {
            trialActive: false,
            trialDaysLeft: 0,
         };
     },
     computed: {
       loggedIn() {
         return this.$store.state.auth.initialState.status.loggedIn;
       },
       subscription() {
          return localStorage.getItem('subscription')
       }
     },
     methods: {
       logout() {
         this.$store.dispatch('auth/logout').then(() => this.$router.push("/login"));
       },
       checkTrial() {
         const user = JSON.parse(localStorage.getItem("user"));
         console.log("User ----->>", user);
         if (user && Number(user.subscriotion_count) === 0 && user.trial_ends_at) {
         console.log("Inside user check condition!!");
         const trialEndsAt = new Date(user.trial_ends_at);
         const today = new Date();
         if (trialEndsAt > today) {
            console.log("Inside trial check condition!!");
            this.trialActive = true;
            this.trialDaysLeft = Math.ceil((trialEndsAt - today) / (1000 * 60 * 60 * 24));
         } else {
            this.trialActive = false;
         }
         }
      },

     },
     mounted(){
      console.log(this.loggedIn, "Is user loggedIn");
      console.log(this.$store.state.auth.initialState.status.loggedIn, 'logged in');
      this.checkTrial();
     }
   }
</script>
<style scoped>
   .trial-status .nav-link {
      font-size: 14px;
      font-weight: bold;
      color: #ffffff !important;
      background-color: #e9191f;
      border: 2px solid #e9191f;
      padding: 20px 50px !important;
      border-radius: 10px;
      left: 1607px;
      top: 48px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
   }
   .trial-status .nav-link:hover {
      font-size: 14px;
      font-weight: bold;
      color: #e9191f !important;
      background-color: #ffffff;
      border: 2px solid #e9191f;
      padding: 20px 50px !important;
      border-radius: 10px;
      left: 1607px;
      top: 48px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
   }
</style>