import axios from 'axios';
const httpOptions = {
    headers:{
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
}

class AuthService {
  login(user) {
    return axios
      .post('user-login', {
        username: user.username,
        password: user.password
      }, httpOptions)
      .then(response => {
        if (response.status==true) {
          console.log("Login response ->", response.data);
          localStorage.clear();
          localStorage.setItem('user', JSON.stringify(response.data));
          localStorage.setItem('jwtToken', response.data.jwtToken);
          if(response.data.subscription==1){
            localStorage.setItem('subscription',1);
          }
          else{
            localStorage.setItem('subscription',0);
          }
        }
        return response;
      })
  }

  register(user) {

    return axios
      .post('register', {email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        company: user.company,
        password: user.password,
        confirm_password: user.confirm_password}, httpOptions)
      .then(response => {
        if (response.status==true) {
          localStorage.setItem('user', JSON.stringify(response.data));
          localStorage.setItem('jwtToken', response.data.jwtToken);
          localStorage.setItem('popup-login','')
          localStorage.setItem('new-login','yes')
          if(response.data.subscription==1){
            localStorage.setItem('subscription',1);
            // this.checkSubscription();
            // console.log("Subscription Count---->", response.data.subscriotion_count);
            // const subscriotion_count = response.data.subscriotion_count;

            // if (subscriotion_count === 0) {
            //   console.log()
            //   localStorage.setItem('trialActive', 'true');
            // } else {
            //   localStorage.setItem('trialActive', 'false');
            // }
          }
        }
        return response;
      })
  }

  // New function to verify email
  verifyEmail(email, code) {
    return axios
      .post('verify-email', {
        email: email,
        verification_code: code
      }, httpOptions)
      .then(response => {
        if (response.status === true) {
          // Set user data and verification status in local storage
          // localStorage.setItem('user', JSON.stringify(response.data.user));
          // localStorage.setItem('jwtToken', response.data.jwtToken);
          localStorage.setItem('emailVerified', 'true'); // Mark email as verified
          console.log("Email verified successfully", response.data);
        }
        return response;
      })
      .catch(error => {
        console.error("Error verifying email:", error);
        throw error;
      });
  }

  // checkSubscription() {
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   const authHeader = () => ({
  //     Authorization: 'Bearer ' + user.token
  //   });
  //   axios
  //     .get("check-subscription", { headers: authHeader() })
  //     .then(response => {
  //       if (response.status === true) {
  //         const { subscriotion_count } = response.data;

  //         if (subscriotion_count === 0) {
  //           localStorage.setItem('trialActive', true);
  //         } else {
  //           localStorage.setItem('trialActive', false);
  //         }
  //       }
  //     })
  //     .catch(error => {
  //       console.error("Error checking subscription:", error);
  //     });
  // }

  logout() {
    localStorage.clear();
  }

  forgotpassword(data) {
    return axios
      .post('forgot-password', {
        email: data.email,
      }, httpOptions)
      .then(response => {
        return response;
      })
  }

  resetpassword(data) {
    return axios
      .post('reset-password', {
        token: data.token,
        password: data.password,
        confirm_password: data.confirm_password,
      }, httpOptions)
      .then(response => {
        return response;
      })
  }

}

export default new AuthService();